<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <UserSelectCard
        v-for="user in selectedUsers"
        :key="user.id"
        :user="user"
        :projectId="projectId"
        :projectNosologiaId="localProjectNosologiaId"
        @update="update"
      />
      <BackToProjectButton />
    </div>
    <div v-else>Нет проекта</div>
  </DisplayLoading>
</template>

<script>
import BackToProjectButton from '@/components/buttons/BackToProjectButton.vue'
import UserSelectCard from '@/components/project/UserSelectCard.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_PROJECTS } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю список пользователей для проекта',
    }
  },
  props: ['users'],
  components: {
    BackToProjectButton,
    UserSelectCard,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_PROJECTS}/${this.projectId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для проекта: ${this.projectId}`
    },
    project() {
      return this.pageData
    },
    localProjectNosologia() {
      return this.project.projectNosologies.find(
        pn => pn.nosology_id === this.nosologiaId
      )
    },
    localProjectNosologiaId() {
      return this.localProjectNosologia.id
    },
    selectedUsers() {
      return this.users.map(u => ({
        ...u,
        ...this.getSelectedData(u.id),
      }))
    },
  },
  methods: {
    getSelectedData(userId) {
      const { userProjectNosologies } = this.localProjectNosologia
      const res = userProjectNosologies.find(upn => upn.user_id === userId)
      return res
        ? { selected: true, userProjectNosologiaId: res.id }
        : { selected: false }
    },
  },
}
</script>
