const FUNCTION = 'function'

export const sendDataWithToastNoRedirect = {
  methods: {
    async sendDataWithToastNoRedirect({
      action,
      data,
      successMessage,
      errorMessage,
      onSuccess,
      onFinally,
    }) {
      try {
        await action(data)
        if (successMessage) this.$toast.success(successMessage)
        if (typeof onSuccess === FUNCTION) onSuccess()
      } catch (e) {
        const fullErrorMessage = errorMessage ? `${errorMessage}'\n'${e}` : e
        this.$toast.error(fullErrorMessage)
        console.error(e)
      } finally {
        if (typeof onFinally === FUNCTION) onFinally()
      }
    },
  },
}
