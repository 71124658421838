<template>
  <h1 v-if="simple" class="mb-6">{{ text }}</h1>
  <div v-else class="d-flex align-center mb-6">
    <h1>{{ text }}</h1>
    <v-spacer></v-spacer>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['text'],
  computed: {
    simple() {
      return Object.keys(this.$slots).length === 0
    },
  },
}
</script>
