<template>
  <v-card class="mb-4">
    <v-container>
      <v-row>
        <v-col class="col-1"
          ><v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
            :size="16"
          ></v-progress-circular>
          <v-checkbox
            v-else
            readonly
            hide-details
            @click="onClick"
            :input-value="user.selected"
          ></v-checkbox
        ></v-col>
        <v-col class="col-5">
          <div class="mr-4">{{ user.fio }}</div></v-col
        >
        <v-col>
          <div>{{ readableProfiles }}</div></v-col
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'
import { pinUser, unpinUser } from '@/lib/requestsAPI'

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  props: ['user', 'projectId', 'projectNosologiaId'],
  mixins: [sendDataWithToastNoRedirect],
  computed: {
    readableProfiles() {
      return this.user.profiles.map(p => p.name).join(', ')
    },
  },
  methods: {
    onCancel() {
      this.isLoading = false
    },
    onClick() {
      if (this.isLoading) return
      this.isLoading = true

      const projects_nosology_id = this.projectNosologiaId
      const user_id = this.user.id

      if (this.user.selected) {
        this.sendDataWithToastNoRedirect({
          action: unpinUser,
          data: this.user.userProjectNosologiaId,
          successMessage: 'Открепили от заболевания (состояния) в проекте',
          errorMessage: `Не удалось открепить пользователя ${user_id} от проекта ${this.projectId}`,
          onSuccess: this.onSuccess,
          onFinally: this.onFinally,
        })
      } else {
        this.sendDataWithToastNoRedirect({
          action: pinUser,
          data: { projects_nosology_id, user_id },
          successMessage:
            'Прикрепили пользователя к заболеванию (состоянию) в проекте',
          errorMessage: `Не удалось прикрепить пользователя ${user_id} к заболеванию (состоянию) в проекте ${this.projectId}`,
          onSuccess: this.onSuccess,
          onFinally: this.onFinally,
        })
      }
    },
    onSuccess() {
      this.$emit('update')
    },
    onFinally() {
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-input {
  margin: 0;
  padding: 0;
}

.v-input >>> .v-input--selection-controls__input {
  margin: 0;
  padding: 0;
}
</style>
