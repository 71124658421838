<template>
  <v-btn @click="onBack">Вернуться к проекту</v-btn>
</template>

<script>
import { routeParams } from '@/mixins/routeParams'
import { ADMIN, PROJECTS, PROJECT_SHORT } from '@/store/const/path'

export default {
  mixins: [routeParams],
  methods: {
    onBack() {
      this.$router.push(
        `/${ADMIN}/${PROJECTS}/${PROJECT_SHORT}-${this.projectId}`
      )
    },
  },
}
</script>
