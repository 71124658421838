<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Добавить НМИЦ к выбранной нозологии" />
      <UsersListWithSelect :users="nmicUsers" />
    </div>
    <div v-else>Нет пользователей для отображения</div>
  </DisplayLoading>
</template>

<script>
import UsersListWithSelect from '@/components/project/UsersListWithSelect.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_USERS } from '@/store/const/apiPaths'
import { USER_NMIC_ID } from '@/store/const/userRoles'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю пользователей',
      loadUrl: API_USERS,
      errorMessage: 'Не удалось загрузить пользователей',
    }
  },
  components: {
    UsersListWithSelect,
    PageHeader,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    nmicUsers() {
      return this.pageData.filter(u => u.role.id === USER_NMIC_ID)
    },
  },
}
</script>
